// src/utility/context/LoadingContext.js

import React, { createContext, useState } from 'react'

export const LoadingContext = createContext({
    loadingGeneral: false,
    setLoadingGeneral: () => { }
})

export const LoadingProvider = ({ children }) => {
    const [loadingGeneral, setLoadingGeneral] = useState(false)

    return (
        <LoadingContext.Provider value={{ loadingGeneral, setLoadingGeneral }}>
            {children}
        </LoadingContext.Provider>
    )
}

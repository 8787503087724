import React, { createContext, useContext, useEffect, useState, useCallback, useMemo } from 'react'
import { AuthContext } from './AuthProvider'
import ReactGA from 'react-ga4'
import { firestore } from '../../firebase'

export const GAConsentContext = createContext()

export const GAConsentProvider = ({ children }) => {
  const [gaConsent, setGaConsent] = useState(null)
  const [loading, setLoading] = useState(true)
  const { currentUser } = useContext(AuthContext)
  const userId = currentUser && currentUser.user_id

  useEffect(() => {
    const fetchGAConsent = async () => {
      if (currentUser) {
        const docRef = firestore.collection('users').doc(userId)
        const doc = await docRef.get()
        if (doc.exists && doc.data().hasOwnProperty('gaConsent')) {
          setGaConsent(doc.data().gaConsent)
        } else {
          setGaConsent(null)
        }
      }
      setLoading(false)
    }

    fetchGAConsent()
  }, [userId, currentUser])

  const updateGAConsent = useCallback((consent) => {
    setGaConsent(consent)
    if (currentUser) {
      const docRef = firestore.collection('users').doc(userId)
      docRef.update({ gaConsent: consent }).then(() => {
        if (consent) ReactGA.set({ userId })
      })
    }
  }, [currentUser, userId])

  const contextValue = useMemo(() => ({
    gaConsent,
    updateGAConsent,
    loading
  }), [gaConsent, updateGAConsent, loading])

  return (
    <GAConsentContext.Provider value={contextValue}>
      {children}
    </GAConsentContext.Provider>
  )
}

// ** React Imports
import { Suspense, lazy, useContext } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

// ** Intl, CASL & ThemeColors Context
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'
import { IntlProviderWrapper } from './utility/context/Internationalization'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'
import UILoader from '@components/ui-loader'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Service Worker
import * as serviceWorker from './serviceWorker'
import { AuthProvider } from './utility/context/AuthProvider'

import { CollectionCountProvider } from './utility/context/CollectionsProvider'
import { ClicksProvider } from './utility/context/ClicksProvider'
import { GAConsentProvider } from './utility/context/GAConsentProvider'
import { AlertProvider } from './context/AlertsContext'
import { LoadingContext, LoadingProvider } from './context/LoadingContext'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

// Create a wrapper component to handle the loading state
const AppWrapper = () => {
  const { loadingGeneral } = useContext(LoadingContext)
  const myLoaderImage = require("../src/assets/images/logo/Loader-02.gif").default
  const imgLoader = <img src={myLoaderImage} alt="Loading" style={{ backgroundColor: "white", border: "1px", borderRadius: 5 }} />

  return (
    <UILoader blocking={loadingGeneral} loader={<div id='register-loader'>{imgLoader}</div>}>
      <LazyApp />
      <ToastContainer newestOnTop />
    </UILoader>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <LoadingProvider>
        <AuthProvider>
          <GAConsentProvider>
            <ClicksProvider>
              <AlertProvider>
                <ThemeContext>
                  <IntlProviderWrapper>
                    <CollectionCountProvider>
                      <AppWrapper />
                    </CollectionCountProvider>
                  </IntlProviderWrapper>
                </ThemeContext>
              </AlertProvider>
            </ClicksProvider>
          </GAConsentProvider>
        </AuthProvider>
      </LoadingProvider>
    </Suspense>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
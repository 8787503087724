// Gardez celui-ci et supprimez l'autre
import React, { useEffect, useState } from 'react'
import { auth, firestore } from '../../firebase'

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [accountLinked, setAccountLinked] = useState(false)
  const [authMethod, setAuthMethod] = useState(null)
  const [userMailVerified, setUserMailVerified] = useState(null)
  const [loading, setLoading] = useState(true) // Ajoutez ceci

  useEffect(() => {
    let unsubscribe
    const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
      setLoading(true) // Ajoutez ceci
      try {
        if (user) {
          const { uid, providerData, emailVerified } = user
          const method = providerData[0]?.providerId.split('.')[0] || 'unknown'
          setAuthMethod(method)
          setUserMailVerified(emailVerified)

          unsubscribe = firestore.collection("users").doc(uid).onSnapshot(snapshot => {
            if (snapshot.exists) {
              setCurrentUser({ ...snapshot.data(), firebaseUser: { ...user } })
            } else {
              setCurrentUser(null)
            }
            setLoading(false) // Ajoutez ceci
          })
        } else {
          setCurrentUser(null)
          setAuthMethod(null)
          setUserMailVerified(null)
          setLoading(false) // Ajoutez ceci
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de l'utilisateur :", error)
        setLoading(false) // Ajoutez ceci
      }
    })

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
      unsubscribeAuth()
    }
  }, [])

  return (
    <AuthContext.Provider value={{
      currentUser,
      accountLinked,
      setAccountLinked,
      authMethod,
      userMailVerified,
      loading // Ajoutez ceci
    }}>
      {!loading && children} {/* Modifiez ceci */}
    </AuthContext.Provider>
  )
}
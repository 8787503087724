import React, { createContext, useState, useEffect, useContext } from 'react'
import { AuthContext } from './AuthProvider'
import { auth, functions } from '../../firebase'

export const ClicksContext = createContext()

export const ClicksProvider = ({ children }) => {
    const [remainingClicks, setRemainingClicks] = useState(null)
    const [timeUntilReset, setTimeUntilReset] = useState(null)
    const { currentUser } = useContext(AuthContext)
    const firebaseAuthUser = auth.currentUser
    const getUserClicksData = functions.httpsCallable('clicks-getUserClicksData')

    const fetchData = async () => {
        try {
            const result = await getUserClicksData()
            const { data } = result
            setTimeUntilReset(data.timeUntilReset)
            setRemainingClicks(data.remainingClicks)
        } catch (error) {
            console.error("Erreur lors de la récupération des données de clics:", error)
        }
    }

    useEffect(() => {
        const checkUserAndFetchData = () => {
            if (currentUser && firebaseAuthUser) {
                if (currentUser.role !== "Admin" && currentUser.role !== "SuperAdmin") {
                    fetchData()
                }
            }
        }

        checkUserAndFetchData()

        // Listener pour les changements d'état d'authentification
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                checkUserAndFetchData()
            } else {
                // Réinitialiser les états si l'utilisateur se déconnecte
                setRemainingClicks(null)
                setTimeUntilReset(null)
            }
        })

        // Nettoyer le listener lors du démontage du composant
        return () => unsubscribe()
    }, [currentUser])

    return (
        <ClicksContext.Provider value={{ remainingClicks, setRemainingClicks, timeUntilReset, setTimeUntilReset, fetchData }}>
            {children}
        </ClicksContext.Provider>
    )
}